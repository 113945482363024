import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { Autocomplete, TextField } from "@mui/material"

import getSubLangs from "../../api/importJobs/getSubLangs"
import { LanguageOption } from "../NewImportJobForm/LanguageSelector"

export default function ImportedVideoLanguagePicker({
  importJobUuid,
  value,
  onChange,
}: {
  importJobUuid: string
  value: LanguageOption | null
  onChange: (value: LanguageOption | null) => void
}) {
  const {
    // status,
    data: options,
    // error: fetchError,
    isFetching: loading,
    // refetch,
  } = useQuery<LanguageOption[], AxiosError>({
    queryKey: ["importJobSubs", importJobUuid],
    queryFn: async () => {
      const langs = await getSubLangs(importJobUuid)
      // onChange(langs.filter((o) => o.id === "en")[0] || langs[0] || null)
      return langs
    },
  })

  return (
    <Autocomplete
      id="caption-lang-select"
      options={options || []}
      disabled={loading || !options}
      size="small"
      style={{ width: "150px" }}
      value={value}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      onChange={(_, value) => {
        value && onChange(value)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={loading ? "Loading languages..." : "Caption Language"}
        />
      )}
    />
  )
}
