import * as React from "react"

import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import EmailIcon from "@mui/icons-material/Email"
import MissedVideoCallIcon from "@mui/icons-material/MissedVideoCall"
import {
  Avatar,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material"
import Alert from "@mui/material/Alert"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"

import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import FullPageContentContainer from "../../components/FullPageContentContainer"
import mdTheme, { zapOrange } from "../../lib/mdTheme"
import { useAppSelector } from "../../redux"
import HomeCard from "../Developers/HomeCard"

export default function ZapierLandingPage() {
  const [errorMessage, setErrorMessage] = React.useState("")
  // const navigate = useNavigate()
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  // const AffiliateChip = (
  //   <Chip
  //     clickable
  //     icon={<AttachMoneyIcon />}
  //     label="Become an affiliate!"
  //     style={{
  //       fontSize: "1rem",
  //       borderWidth: "3px",
  //     }}
  //     color="info"
  //     variant="filled"
  //     onClick={(e) => {
  //       e.preventDefault()
  //       window.open("https://trimmr.getrewardful.com/signup", "_blank")
  //     }}
  //   />
  // )

  return (
    <>
      <AppBar title="Trimmr + Zapier" />

      <Toolbar />

      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      <FullPageContentContainer
        maxWidth="md"
        minHeight={"0px"}
        sx={{ py: 5, pt: 2 }}
      >
        <Stack direction="row" justifyContent="center" sx={{ my: 2, mb: 5 }}>
          <Typography
            variant="h1"
            fontSize="4rem"
            color="text.primary"
            textAlign="center"
            lineHeight="1"
          >
            Automate your content with Trimmr + Zapier
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="center" sx={{ my: 2, mb: 5 }}>
          <Typography
            variant="h2"
            fontSize="2rem"
            color="text.secondary"
            textAlign="center"
            lineHeight="1"
          >
            Our Zapier integration allows you to automatically repurpose content
            without lifting a finger.
          </Typography>
        </Stack>
        {/* <Paper sx={{ bgcolor: "background.paper" }}> */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <zapier-zap-templates
          sign-up-email="email_of_your_user@example.com"
          sign-up-first-name="first_name_of_your_user"
          sign-up-last-name="last_name_of_your_user"
          client-id="sf00LohWSl1qgld5vW3rrEKdLQa2rDEfd89r3pgp"
          theme={darkModeEnabled ? "dark" : "light"}
          apps="trimmr"
          create-without-template="hide"
          limit={5}
          use-this-zap="show"
        />
      </FullPageContentContainer>

      <FullPageContentContainer maxWidth="md">
        <Grid container spacing={4} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={7}>
            <HomeCard variant="black">
              <Stack
                height="100%"
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={8}
              >
                <img
                  src={"/img/zapier-logo_black.svg"}
                  style={{ height: "50px" }}
                />
                <Typography variant="h2" fontSize="2rem" textAlign="center">
                  Search for <b>Trimmr</b> when creating your next automation.
                </Typography>
              </Stack>
            </HomeCard>
          </Grid>
          <Grid item xs={12} sm={5}>
            <HomeCard variant="zapier">
              <Stack
                height="100%"
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={4}
              >
                <Typography variant="h2" fontSize="8rem">
                  🦾
                </Typography>
                <Typography variant="h2" fontSize="2rem" textAlign="center">
                  More reach, less effort.
                </Typography>
              </Stack>
            </HomeCard>
          </Grid>
        </Grid>
        <Grid container spacing={4} flexWrap="wrap-reverse">
          <Grid item xs={12} sm={5}>
            <HomeCard variant="zapier">
              <Stack
                height="100%"
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={4}
              >
                <Typography variant="h2" fontSize="8rem">
                  😍
                </Typography>
                <Typography variant="h2" fontSize="2rem" textAlign="center">
                  Content on autopilot.
                </Typography>
              </Stack>
            </HomeCard>
          </Grid>
          <Grid item xs={12} sm={7}>
            <HomeCard variant="black">
              <Stack
                height="100%"
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={4}
                sx={{ py: 2, pr: 4 }}
              >
                <List>
                  <ListItem sx={{ my: 2, mx: 2 }}>
                    <ListItemAvatar color="info">
                      <Avatar sx={{ bgcolor: zapOrange }}>
                        <CloudDownloadIcon
                          sx={{ color: mdTheme.palette.background.paper }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText color="textPrimary">
                      <div style={{ lineHeight: 1.1 }}>
                        Automatically import videos into Trimmr.
                      </div>
                    </ListItemText>
                  </ListItem>
                  <ListItem sx={{ my: 2, mx: 2 }}>
                    <ListItemAvatar color="info">
                      <Avatar sx={{ bgcolor: zapOrange }}>
                        <MissedVideoCallIcon
                          sx={{ color: mdTheme.palette.background.paper }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText color="textPrimary">
                      <div style={{ lineHeight: 1.1 }}>
                        Generate ideas whenever a video is imported.
                      </div>
                    </ListItemText>
                  </ListItem>
                  <ListItem sx={{ my: 2, mx: 2 }}>
                    <ListItemAvatar color="info">
                      <Avatar sx={{ bgcolor: zapOrange }}>
                        <AutoFixHighIcon
                          sx={{ color: mdTheme.palette.background.paper }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText color="textPrimary">
                      <div style={{ lineHeight: 1.1 }}>
                        Render videos and upload them to your social media.
                      </div>
                    </ListItemText>
                  </ListItem>
                </List>
              </Stack>
            </HomeCard>
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="center" sx={{ mt: 5 }}>
          <Chip
            clickable
            icon={<EmailIcon />}
            label="Contact us for more information"
            style={{
              fontSize: "1rem",
              borderWidth: "3px",
            }}
            color="info"
            variant="filled"
            onClick={(e) => {
              e.preventDefault()
              window.open("/app/contact", "_blank")
            }}
          />
        </Stack>
      </FullPageContentContainer>

      <Footer />
    </>
  )
}
