import * as React from "react"

import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange"
import EmailIcon from "@mui/icons-material/Email"
import EventAvailableIcon from "@mui/icons-material/EventAvailable"
import InsertLinkIcon from "@mui/icons-material/InsertLink"
import {
  Avatar,
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material"
import Alert from "@mui/material/Alert"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"

import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import FullPageContentContainer from "../../components/FullPageContentContainer"
import mdTheme from "../../lib/mdTheme"
import HomeCard from "../Developers/HomeCard"

export default function Affiliates() {
  const [errorMessage, setErrorMessage] = React.useState("")

  const AffiliateChip = (
    <Chip
      clickable
      icon={<AttachMoneyIcon />}
      label="Become an affiliate!"
      style={{
        fontSize: "1rem",
        borderWidth: "3px",
      }}
      color="info"
      variant="filled"
      onClick={(e) => {
        e.preventDefault()
        window.open("https://trimmr.getrewardful.com/signup", "_blank")
      }}
    />
  )

  return (
    <>
      <AppBar title="Trimmr Affiliates" />

      <Toolbar />

      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      <Stack direction="row" justifyContent="center" sx={{ pt: 5 }}>
        {AffiliateChip}
      </Stack>

      <FullPageContentContainer
        maxWidth="md"
        minHeight={"0px"}
        sx={{ py: 5, pt: 2 }}
      >
        <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
          <Typography
            variant="h2"
            fontSize="4rem"
            color="text.primary"
            textAlign="center"
            lineHeight="1"
          >
            Earn <span style={{ fontWeight: "bold" }}>50% commission</span> on
            all <span style={{ fontWeight: "bold" }}>referrals</span>
          </Typography>
        </Stack>

        {/* <Stack direction="row" justifyContent="center" sx={{ my: 5 }}>
          <Button variant="contained" color="primary" size="large">
            <span style={{ fontSize: "1rem", marginTop: "-2px" }}>
              Get Started
            </span>
          </Button>
        </Stack> */}
      </FullPageContentContainer>

      <FullPageContentContainer maxWidth="md">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={7}>
            <HomeCard variant="black">
              <Stack
                height="100%"
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={8}
              >
                <img
                  role="presentation"
                  src={"/img/rewardful-dark.svg"}
                  style={{ height: "50px" }}
                />
                <Typography variant="h2" fontSize="2rem" textAlign="center">
                  It only takes minutes to become an affiliate with Rewardful.
                </Typography>

                {AffiliateChip}
              </Stack>
            </HomeCard>
          </Grid>
          <Grid item xs={12} sm={5}>
            <HomeCard containerClassName="bg-home-parallax-position-large">
              <Stack
                height="100%"
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={4}
              >
                <Typography variant="h2" fontSize="8rem">
                  💸
                </Typography>
                <Typography
                  variant="h2"
                  fontSize="2rem"
                  textAlign="center"
                  style={{ color: "white" }}
                >
                  No minimum payout threshold!
                </Typography>
              </Stack>
            </HomeCard>
          </Grid>
        </Grid>
        <Grid container spacing={4} sx={{ mt: 4 }} flexWrap="wrap-reverse">
          <Grid item xs={12} sm={5}>
            <HomeCard containerClassName="bg-home-parallax-position-right">
              <Stack
                height="100%"
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={4}
              >
                <Typography variant="h2" fontSize="8rem">
                  😍
                </Typography>
                <Typography
                  variant="h2"
                  fontSize="2rem"
                  textAlign="center"
                  style={{ color: "white" }}
                >
                  Earn recurring revenue.
                </Typography>
              </Stack>
            </HomeCard>
          </Grid>
          <Grid item xs={12} sm={7}>
            <HomeCard variant="black">
              <Stack
                height="100%"
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={4}
                sx={{ p: 4, pr: 8 }}
              >
                <Stack
                  height="100%"
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <Box
                    style={{ borderRadius: "1rem", backgroundColor: "white" }}
                    sx={{ p: 1, px: 3 }}
                  >
                    <img
                      role="presentation"
                      src={"/img/paypal.svg"}
                      style={{ height: "40px" }}
                    />
                  </Box>

                  <Typography
                    variant="body1"
                    textAlign="center"
                    lineHeight={1.1}
                  >
                    Payouts are made via Paypal after 30 days (to account for
                    refunds).
                  </Typography>
                </Stack>

                <List>
                  <ListItem sx={{ my: 2, mx: 2 }}>
                    <ListItemAvatar color="info">
                      <Avatar sx={{ bgcolor: mdTheme.palette.info.main }}>
                        <InsertLinkIcon
                          sx={{ color: mdTheme.palette.background.paper }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText color="textPrimary">
                      <div style={{ lineHeight: 1.1 }}>
                        After signing up, you will be granted a referral link.
                      </div>
                    </ListItemText>
                  </ListItem>
                  <ListItem sx={{ my: 2, mx: 2 }}>
                    <ListItemAvatar color="info">
                      <Avatar sx={{ bgcolor: mdTheme.palette.info.main }}>
                        <EventAvailableIcon
                          sx={{ color: mdTheme.palette.background.paper }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText color="textPrimary">
                      <div style={{ lineHeight: 1.1 }}>
                        Users who access your link will receive a 60-day cookie.
                      </div>
                    </ListItemText>
                  </ListItem>
                  <ListItem sx={{ my: 2, mx: 2 }}>
                    <ListItemAvatar color="info">
                      <Avatar sx={{ bgcolor: mdTheme.palette.info.main }}>
                        <CurrencyExchangeIcon
                          sx={{ color: mdTheme.palette.background.paper }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText color="textPrimary">
                      <div style={{ lineHeight: 1.1 }}>
                        You'll earn a commission on the user's first twelve
                        months.
                      </div>
                    </ListItemText>
                  </ListItem>
                </List>
              </Stack>
            </HomeCard>
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="center" sx={{ mt: 5 }}>
          <Chip
            clickable
            icon={<EmailIcon />}
            label="Contact us for more information"
            style={{
              fontSize: "1rem",
              borderWidth: "3px",
            }}
            color="info"
            variant="filled"
            onClick={(e) => {
              e.preventDefault()
              window.open("/app/contact", "_blank")
            }}
          />
        </Stack>
      </FullPageContentContainer>

      <Footer />
    </>
  )
}
