import * as React from "react"

import AlarmOnIcon from "@mui/icons-material/AlarmOn"
// import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import LoadingButton from "@mui/lab/LoadingButton"
import { Alert, AlertTitle, Tooltip } from "@mui/material"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import { ESTIMATED_IMPORT_TIME_SECS } from "../../consts"
import { isValidYoutubeURL } from "../../lib/isValidYoutubeURL"
import usePrevious from "../../lib/usePrevious"
import { useAppSelector } from "../../redux"
import Progress from "../Progress"
import SmallAlert from "../SmallAlert"
import LanguageSelector, { LanguageOption } from "./LanguageSelector"

const NewImportJobForm = ({
  youtubeURL,
  setYoutubeURL,
  isSaving,
  waitForLanguageToLoad = true,
  handleCreateImportJob,
  languageOption,
  setLanguageOption,
}: {
  youtubeURL: string
  setYoutubeURL: (youtubeURL: string) => void
  isSaving: boolean
  waitForLanguageToLoad?: boolean
  handleCreateImportJob: (
    formData?: FormData,
    onUploadProgress?: (progressEvent: ProgressEvent) => void
  ) => void

  languageOption: LanguageOption[]
  setLanguageOption?: (value: LanguageOption[]) => void
}) => {
  const [languageOptionsLoaded, setLanguageOptionsLoaded] = React.useState(true)
  const [uploadedVideo, setUploadedVideo] = React.useState<File | null>(null)
  const [uploadProgress, setUploadProgress] = React.useState<number>(0)
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const previousYoutubeURL = usePrevious(youtubeURL)
  React.useEffect(() => {
    youtubeURL &&
      previousYoutubeURL !== youtubeURL &&
      setLanguageOptionsLoaded(false)
  }, [previousYoutubeURL, youtubeURL])

  const onUploadProgress = React.useCallback(
    (event: ProgressEvent) => {
      setUploadProgress(Math.round((event.loaded * 100) / event.total))
    },
    [setUploadProgress]
  )

  const uploadVideo = React.useCallback(
    async (uploadedVideo: File) => {
      setUploadedVideo(null)
      const reader = new FileReader()
      reader.readAsDataURL(uploadedVideo)
      reader.onloadend = async () => {
        const formData = new FormData()
        if (uploadedVideo)
          formData.append("file", uploadedVideo, uploadedVideo.name)
        formData.append(
          "languageKeys",
          languageOption.map((o) => o.id).join(",")
        )
        await handleCreateImportJob(formData, onUploadProgress)
        setUploadProgress(0)
      }
    },
    [handleCreateImportJob, languageOption, onUploadProgress]
  )

  React.useEffect(() => {
    if (uploadedVideo) {
      uploadVideo(uploadedVideo)
    }
  }, [uploadVideo, uploadedVideo])

  const disabled = !isValidYoutubeURL(youtubeURL)

  return (
    <Box>
      <TextField
        name="youtubeURL"
        required
        fullWidth
        id="youtubeURL"
        value={youtubeURL || ""}
        label="Youtube URL"
        onChange={(e) => setYoutubeURL(e.target.value)}
        size="small"
      />

      {uploadProgress === 0 && (
        <>
          {setLanguageOption && youtubeURL && currentUserProfile && (
            <Box sx={{ my: 2 }}>
              <LanguageSelector
                youtubeURL={youtubeURL}
                onChange={setLanguageOption}
                value={languageOption}
                onOptionsLoaded={() => setLanguageOptionsLoaded(true)}
              />
            </Box>
          )}
          <Stack
            direction="row"
            sx={{ mt: 2 }}
            flexWrap="wrap"
            alignItems="center"
            justifyContent="flex-start"
            style={{ gap: "0.5rem" }}
          >
            <LoadingButton
              onClick={() => handleCreateImportJob()}
              loading={
                isSaving || (!languageOptionsLoaded && waitForLanguageToLoad)
              }
              disabled={disabled}
              variant="contained"
              size="small"
              style={{ maxWidth: "400px" }}
            >
              {!languageOptionsLoaded && waitForLanguageToLoad
                ? "Loading languages..."
                : "Import video"}
            </LoadingButton>

            <SmallAlert
              severity="warning"
              icon={<AlarmOnIcon />}
              sx={{ px: 1, py: 0 }}
            >
              <Tooltip title="Estimated wait time">
                <Typography variant="subtitle2" textAlign="right">
                  ~{ESTIMATED_IMPORT_TIME_SECS} seconds
                </Typography>
              </Tooltip>
            </SmallAlert>
          </Stack>

          {currentUserProfile && currentUserProfile.isAdmin && (
            <Alert
              sx={{ mt: 2 }}
              color="warning"
              icon={false}
              variant="outlined"
              style={{ opacity: 0.5 }}
            >
              <AlertTitle>
                Admin-only video upload feature, takes a number of minutes
              </AlertTitle>
              <Stack
                direction="row"
                sx={{ mt: 2 }}
                flexWrap="wrap"
                alignItems="center"
                justifyContent="flex-start"
                style={{ gap: "0.5rem" }}
              >
                <label htmlFor="contained-button-file">
                  <input
                    style={{ display: "none" }}
                    accept=".mp4,.mov,.avi,.mkv,.webm"
                    id="contained-button-file"
                    type="file"
                    onChange={(e) =>
                      e.target.files && setUploadedVideo(e.target.files[0])
                    }
                  />
                  <LoadingButton
                    loading={isSaving}
                    variant="contained"
                    size="small"
                    component="span"
                  >
                    Upload Video
                  </LoadingButton>
                </label>
              </Stack>
            </Alert>
          )}
        </>
      )}

      {uploadProgress > 0 && (
        <Box sx={{ mt: 2 }}>
          <Progress progressPercent={uploadProgress} />
          <Typography sx={{ mt: 2 }}>Uploading video...</Typography>
        </Box>
      )}
    </Box>
  )
}

export default NewImportJobForm
