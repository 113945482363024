import { useNavigate } from "react-router-dom"

import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import TerminalIcon from "@mui/icons-material/Terminal"
import { Chip, Stack, useMediaQuery } from "@mui/material"
import MuiAppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { ThemeProvider, styled } from "@mui/material/styles"

import { darkTheme, lightTheme } from "../../lib/mdTheme"
import { useAppSelector } from "../../redux"
import { DRAWER_WIDTH } from "../CreateRenderJobModal/RemotionPreview/constants"
import DarkModeToggle from "../DarkModeToggle"
import AppBarItems from "./AppBarItems"

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<{ open?: boolean }>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const AppBarComponent = ({
  title,
  invert,
  hideDarkModePicker,
  hideTitle,
  showDevelopers,
  showAffiliate,
}: {
  title?: string
  invert?: boolean
  hideDarkModePicker?: boolean
  hideTitle?: boolean
  showDevelopers?: boolean
  showAffiliate?: boolean
}) => {
  const navigate = useNavigate()
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const appBarStyle = invert
    ? {
        backgroundColor: "rgba(0,0,0,0)",
        boxShadow: "none",
        border: "none",
      }
    : {}

  const typographyColor = invert ? "primary" : "inherit"

  const isSmallScreen = useMediaQuery("(max-width: 640px)")

  return (
    <ThemeProvider theme={darkModeEnabled ? darkTheme : lightTheme}>
      <AppBar position="absolute" style={appBarStyle}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          {!title && (
            <Box sx={{ flexGrow: 1 }}>
              {/* keep box for right-aligning the burger icon */}
              {!hideTitle && (
                <Button color="inherit" onClick={() => navigate("/app")}>
                  Home
                </Button>
              )}
            </Box>
          )}

          {title && (
            <Typography
              component="h1"
              variant="h6"
              color={typographyColor}
              noWrap
              sx={{ flexGrow: 1 }}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/app")}
            >
              {title}
            </Typography>
          )}

          <Stack direction="row" spacing={2} alignItems="center">
            {!isSmallScreen && showDevelopers && (
              <Chip
                clickable
                title="Go to the Developers page"
                icon={<TerminalIcon />}
                label="Developers"
                color="info"
                // variant="outlined"
                onClick={() => navigate("/app/developers")}
              />
            )}

            {!isSmallScreen && showAffiliate && (
              <Chip
                clickable
                icon={<AttachMoneyIcon />}
                label="Become an affiliate!"
                style={{
                  fontSize: "1rem",
                  borderWidth: "3px",
                }}
                color="info"
                onClick={() => navigate("/app/affiliates")}
              />
            )}

            {currentUserProfile &&
              !currentUserProfile.hasActiveStripeSubscription && (
                <Button
                  sx={{ mr: 2 }}
                  variant="outlined"
                  color="inherit"
                  size="small"
                  onClick={() => navigate("/app/account/subscription")}
                >
                  Upgrade
                </Button>
              )}

            {!hideDarkModePicker && <DarkModeToggle />}

            <AppBarItems />
          </Stack>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}

export default AppBarComponent
