import * as React from "react"

import FilterContainer from "packages/frontend/src/components/FilterContainer"

import { Asset } from "@trimmr/trimmr-lib/types/Asset"

import ImageIcon from "@mui/icons-material/Image"
import PublicIcon from "@mui/icons-material/Public"
import TheatersIcon from "@mui/icons-material/Theaters"
import {
  Breakpoint,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  Tooltip,
} from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Pagination from "@mui/material/Pagination"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import searchAssets, { SearchAssetParams } from "../../../../api/assets/search"
import ConditionalInfoAlert from "../../../../components/ConditionalInfoAlert"
import CreatedAtChip from "../../../../components/CreatedAtChip"
import HeadingContainer from "../../../../components/HeadingContainer"
import durationToMSString from "../../../../helpers/soundFile/durationToMSString"
import { darkTheme } from "../../../../lib/mdTheme"
import { useAppSelector } from "../../../../redux"
import AssetButtonGroup from "./AssetButtonGroup"
import CreateAssetModal from "./CreateAssetModal"

let queryTimeout: NodeJS.Timeout

interface Props {
  asAdmin?: boolean
  hideHeading?: boolean
  hideFilterLabel?: boolean
  maxWidth?: false | Breakpoint
  disableGutters?: boolean
  selectorMode?: boolean
  onSelectAsset?: (asset: Asset) => void
  perPage?: number
  horizontalItems?: boolean
}

export const AssetTypeChip: React.FC<{ asset: Asset }> = ({ asset }) => {
  return (
    <>
      {asset.type === "VIDEO" && (
        <Chip
          icon={<TheatersIcon />}
          size="small"
          label="Video"
          color="info"
          sx={{
            backgroundColor: (theme) => theme.palette.primary.light,
          }}
        />
      )}
      {asset.type === "IMAGE" && (
        <Chip
          sx={{
            backgroundColor: (theme) => theme.palette.info.light,
          }}
          icon={<ImageIcon />}
          color="info"
          size="small"
          label="Image"
        />
      )}
      {asset.public && (
        <Chip
          icon={<PublicIcon />}
          color="success"
          size="small"
          label="Public"
        />
      )}
    </>
  )
}
const AssetsManagement: React.FC<Props> = ({
  asAdmin,
  hideHeading,
  hideFilterLabel,
  maxWidth,
  disableGutters,
  selectorMode,
  onSelectAsset,
  perPage = 12,
  horizontalItems,
}) => {
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  // const [status, setStatus] = React.useState("any")
  const [email, setEmail] = React.useState("")
  const [assetsLoading, setAssetsLoading] = React.useState(true)
  const [createAssetModalShown, setCreateAssetModalShown] =
    React.useState(false)
  const [assets, setAssets] = React.useState<Asset[] | null>(null)
  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)
  const [totalAssets, setTotalAssets] = React.useState(0)
  const [name, setName] = React.useState("")
  const [publicAssets, setPublic] = React.useState("all")

  const [errorMessage, setErrorMessage] = React.useState("")

  const handleSearchAssets = React.useCallback(
    ({ name, email, publicAssets }) => {
      const params: SearchAssetParams = {
        page,
        perPage,
        email,
        public: publicAssets === "public" ? true : false,
        mine: publicAssets === "mine" ? true : false,
        name,
        asAdmin,
        userUuid: asAdmin
          ? ""
          : currentUserProfile && publicAssets !== "all"
          ? currentUserProfile.uuid
          : "",
      }

      searchAssets(params)
        .then(({ assets, totalPages, totalResults }) => {
          setAssets(assets)
          setTotalPages(totalPages)
          setTotalAssets(totalResults)
          setAssetsLoading(false)
        })
        .catch((err) => {
          console.error(err)
          setAssetsLoading(false)
          setErrorMessage(
            (err.response && err.response.data && err.response.data.error) ||
              err.message
          )
        })
    },
    [page, perPage, asAdmin, currentUserProfile]
  )

  React.useEffect(() => {
    setAssetsLoading(true)
    clearTimeout(queryTimeout)
    queryTimeout = setTimeout(() => {
      handleSearchAssets({
        name,
        email,
        publicAssets,
      })
    }, 300)
  }, [page, handleSearchAssets, name, email, publicAssets])

  return (
    <div>
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            {errorMessage}
          </Alert>
        </Stack>
      )}

      {!hideHeading && (
        <HeadingContainer>
          <Typography component="h1" variant="h6" color="textPrimary" noWrap>
            Assets
          </Typography>
        </HeadingContainer>
      )}

      <FilterContainer hideFilterLabel={hideFilterLabel}>
        <FormControl style={{ width: "90px" }}>
          <InputLabel size="small" id="status">
            Type
          </InputLabel>
          <Select
            fullWidth
            size="small"
            labelId="public"
            label="Type"
            value={publicAssets}
            onChange={(e) => setPublic(e.target.value)}
          >
            <MenuItem value="all">
              <Typography variant="body1">All</Typography>
            </MenuItem>
            <MenuItem value="mine">
              <Typography variant="body1">Mine</Typography>
            </MenuItem>
            <MenuItem value="public">
              <Typography variant="body1">Public</Typography>
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          size="small"
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
          label="Name"
          variant="outlined"
        />

        {asAdmin && (
          <TextField
            size="small"
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            autoComplete="off"
            variant="outlined"
          />
        )}

        <Stack flexGrow={1} alignItems="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCreateAssetModalShown(true)}
          >
            Upload
          </Button>
        </Stack>

        <CreateAssetModal
          open={createAssetModalShown}
          onClose={() => setCreateAssetModalShown(false)}
          onAssetCreate={() => {
            handleSearchAssets({ name, publicAssets, email })
            setCreateAssetModalShown(false)
          }}
        />
      </FilterContainer>

      {!selectorMode && <Divider sx={{ mb: 2 }} />}

      <Container maxWidth={maxWidth ?? "md"} disableGutters={disableGutters}>
        {!selectorMode && (
          <ConditionalInfoAlert>
            Manage your graphic asset library from here. Uploading images and
            videos to your asset library makes them available for you to overlay
            and mix into your shorts.
          </ConditionalInfoAlert>
        )}

        {assetsLoading && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
            <CircularProgress color="inherit" />
          </Box>
        )}

        {!assetsLoading && (
          <>
            <Paper sx={{ mb: 2, p: 2 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" color="textSecondary">
                  {totalAssets} assets
                </Typography>

                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={(_, v) => setPage(v)}
                />
              </Stack>
            </Paper>

            <Grid container columns={12} spacing={2} alignItems="stretch">
              {assets &&
                assets.map((asset) => (
                  <Grid
                    item
                    xs={12}
                    sm={horizontalItems ? 12 : 4}
                    key={asset.uuid}
                  >
                    <Paper key={asset.uuid} style={{ height: "100%" }}>
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        style={{ height: "100%" }}
                        sx={{ p: 1 }}
                      >
                        <Stack direction="column">
                          <Tooltip
                            placement="top"
                            title={
                              <ThemeProvider theme={darkTheme}>
                                <Stack
                                  direction="row"
                                  flexWrap="wrap"
                                  gap="0.5rem"
                                  sx={{ py: 0.5 }}
                                >
                                  <AssetTypeChip asset={asset} />

                                  <CreatedAtChip createdAt={asset.createdAt} />

                                  {asset.originalWidth && (
                                    <Chip
                                      size="small"
                                      variant="outlined"
                                      label={`Width ${asset.originalWidth}px`}
                                    />
                                  )}
                                  {asset.originalHeight && (
                                    <Chip
                                      size="small"
                                      variant="outlined"
                                      label={`Height ${asset.originalHeight}px`}
                                    />
                                  )}
                                  {typeof asset.originalDuration === "number" &&
                                    asset.originalDuration > 0 && (
                                      <Chip
                                        size="small"
                                        variant="outlined"
                                        label={`Duration ${durationToMSString(
                                          asset.originalDuration,
                                          true
                                        )}`}
                                      />
                                    )}
                                </Stack>
                              </ThemeProvider>
                            }
                          >
                            <Box
                              sx={{ mb: 1 }}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              style={{
                                backgroundColor: "#333",
                                minHeight: "50px",
                                width: "100%",
                                aspectRatio: "1/1",
                              }}
                            >
                              <img
                                src={asset.thumbnailUrl || asset.assetUrl}
                                alt="Thumbnail"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                }}
                              />
                            </Box>
                          </Tooltip>

                          <Typography
                            variant="subtitle2"
                            color="text.primary"
                            sx={{ mb: 1 }}
                            data-asset-uuid={asset.uuid}
                          >
                            {asset.name}
                          </Typography>

                          {asAdmin && asset.user && (
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              sx={{ mb: 1 }}
                            >
                              Created by {asset.user.email}
                            </Typography>
                          )}
                        </Stack>
                        <Box>
                          {selectorMode && (
                            <Chip
                              icon={
                                asset.type === "VIDEO" ? (
                                  <TheatersIcon />
                                ) : (
                                  <ImageIcon />
                                )
                              }
                              clickable
                              onClick={() =>
                                onSelectAsset && onSelectAsset(asset)
                              }
                              label="Add Asset"
                              size="small"
                              color="success"
                            />
                          )}
                        </Box>
                        {!selectorMode &&
                          currentUserProfile &&
                          (currentUserProfile.uuid === asset.userUuid ||
                            currentUserProfile.isAdmin) && (
                            <AssetButtonGroup
                              asset={asset}
                              onAssetChanged={() =>
                                handleSearchAssets({
                                  name,
                                  email,
                                  publicAssets,
                                })
                              }
                            />
                          )}
                      </Stack>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </Container>
    </div>
  )
}

export default AssetsManagement
