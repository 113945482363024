import { LanguageOption } from "../../components/NewImportJobForm/LanguageSelector"
import { API_BASE_URL } from "../../consts"
import axios from "../axios"

export default function getSubLangs(
  importJobUuid: string
): Promise<LanguageOption[]> {
  return axios
    .get(`${API_BASE_URL}/import-jobs/${importJobUuid}/sub-langs`)
    .then((response) => response.data)
}
