import * as React from "react"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material"

// import usePrevious from "../../lib/usePrevious"
import { useAppSelector } from "../../redux"

const AccordionSection = ({
  icon,
  title,
  children,
  style,
  open = false,
  initialOpen = true,
  setOpen,
  alwaysRender = false,
  disableGutters = false,
}: {
  icon?: React.ReactNode
  title?: string
  children: React.ReactNode
  style?: React.CSSProperties
  // we still react to open changes in the open direction
  open?: boolean
  initialOpen?: boolean
  setOpen?: (open: boolean) => void
  alwaysRender?: boolean
  disableGutters?: boolean
}) => {
  const [expanded, setExpanded] = React.useState(initialOpen)
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  // if not controlled we can still set open
  React.useEffect(() => {
    if (open === undefined) setOpen && setOpen(expanded)
  }, [expanded, open, setOpen])

  return (
    <Accordion
      square
      disableGutters={disableGutters}
      elevation={darkModeEnabled ? 0 : 4}
      expanded={open || expanded}
      onChange={(_, value) => (setOpen ? setOpen(value) : setExpanded(value))}
      style={style}
    >
      {(icon || title) && (
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" flexDirection="row" alignItems="center">
            {icon}
            {title && (
              <Typography sx={{ ml: icon ? 2 : 0 }}>{title}</Typography>
            )}
          </Box>
        </AccordionSummary>
      )}

      <AccordionDetails sx={{ p: 2, pt: 0 }}>
        {(open || expanded || alwaysRender) && <>{children}</>}
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionSection
