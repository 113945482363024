export default function youtubeIdFromUrl(youtubeURL: string): string {
  try {
    // Check if the URL matches the "https://www.youtube.com/live/" format
    if (
      youtubeURL.includes("www.youtube.com/live/") ||
      youtubeURL.includes("youtube.com/live/")
    ) {
      // Extract the video ID from the URL
      const videoIdMatch = youtubeURL.match(/\/live\/([A-Za-z0-9_-]+)/)
      if (videoIdMatch && videoIdMatch.length > 1) {
        // Return the video ID
        return videoIdMatch[1]
      }
    }

    // Check if the URL matches the "https://www.youtube.com/watch?v=" format
    if (
      youtubeURL.includes("www.youtube.com/watch?v=") ||
      youtubeURL.includes("youtube.com/watch?v=")
    ) {
      // Extract the video ID from the URL
      const videoIdMatch = youtubeURL.match(/v=([A-Za-z0-9_-]+)/)
      if (videoIdMatch && videoIdMatch.length > 1) {
        // Return the video ID
        return videoIdMatch[1]
      }
    }

    if (youtubeURL.includes("youtu.be")) {
      // Extract the video ID from the youtu.be URL
      const videoIdMatch = youtubeURL.match(/youtu.be\/([A-Za-z0-9_-]+)/)
      if (videoIdMatch && videoIdMatch.length > 1) {
        // Return the video ID
        return videoIdMatch[1]
      }
    }

    return ""
  } catch (err) {
    // If there's an error, return an empty string
    return ""
  }
}
