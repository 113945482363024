import { LanguageOption } from "../../components/NewImportJobForm/LanguageSelector"
import { API_BASE_URL } from "../../consts"
import axios from "../axios"

export default function getAvailableSubLangs(
  youtubeURL: string
): Promise<LanguageOption[]> {
  return axios
    .post(`${API_BASE_URL}/import-jobs/find-available-sub-langs`, {
      youtubeURL,
    })
    .then((response) => response.data)
}
