import youtubeIdFromUrl from "../../lib/youtubeIdFromUrl"

const YoutubePreview = ({ youtubeURL }: { youtubeURL: string }) => {
  const youtubeId = youtubeIdFromUrl(youtubeURL || "")

  return (
    <div
      style={{
        overflow: "hidden",
        paddingBottom: "56.25%",
        position: "relative",
        height: 0,
      }}
    >
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${youtubeId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        style={{
          left: 0,
          top: 0,
          height: "100%",
          width: "100%",
          position: "absolute",
        }}
      />
    </div>
  )
}

export default YoutubePreview
