import * as React from "react"

import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useDebounce } from "usehooks-ts"

import { Autocomplete, TextField } from "@mui/material"

import getAvailableSubLangs from "../../api/importJobs/getAvailableSubLangs"
import cleanYoutubeUrl from "../../lib/cleanYoutubeURL"
import { isValidYoutubeURLMessage } from "../../lib/isValidYoutubeURL"
import SmallAlert from "../SmallAlert"

export interface LanguageOption {
  id: string
  name: string
  autogenerated: boolean
}

export default function LanguageSelector({
  youtubeURL,
  value,
  onChange,
  onOptionsLoaded,
}: {
  youtubeURL: string
  value: LanguageOption[]
  onChange: (value: LanguageOption[]) => void
  onOptionsLoaded?: () => void
}) {
  const debouncedYoutubeURL = useDebounce(youtubeURL, 500)
  const [errorMessage, setErrorMessage] = React.useState("")
  const {
    // status,
    data: options,
    error: fetchError,
    isFetching: loading,
    // refetch,
  } = useQuery<LanguageOption[], AxiosError | Error>({
    queryKey: ["getAvailableSubLangs", debouncedYoutubeURL],
    retry: false,
    queryFn: async () => {
      setErrorMessage("")
      onChange([])
      const invalidURLMessage = isValidYoutubeURLMessage(debouncedYoutubeURL)
      if (invalidURLMessage) {
        throw new Error(invalidURLMessage)
      }
      try {
        const ytClean = cleanYoutubeUrl(debouncedYoutubeURL)
        if (!ytClean) return []
        const options = await getAvailableSubLangs(ytClean)
        const firstSub = options.filter((o) => o.id === "en")[0]
        onChange(firstSub ? [firstSub] : [])
        onOptionsLoaded && onOptionsLoaded()
        return options
      } catch (err) {
        console.error("Error fetching subtitles", err)
        throw new Error("Error fetching subtitles, check Youtube URL.")
      }
    },
  })

  React.useEffect(() => {
    if (options && options.filter((o) => o.id === "en").length === 0)
      setErrorMessage(
        "No English captions found, select another language. Idea generation will not work for this video."
      )
    if (fetchError) setErrorMessage(fetchError.message)
  }, [fetchError, options])

  return (
    <>
      {!loading && errorMessage && (
        <SmallAlert
          severity="error"
          sx={{ mb: 2 }}
          onClose={() => setErrorMessage("")}
        >
          {errorMessage}
        </SmallAlert>
      )}
      <Autocomplete
        multiple
        id="attempt-import-lang-select"
        options={options || []}
        value={value}
        renderOption={(props, option) => (
          <li
            {...props}
            key={`${option.id}-${option.autogenerated ? "autogenerated" : ""}`}
          >
            {option && option.name}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        fullWidth
        size="small"
        disabled={loading || !options}
        getOptionLabel={(option) => option && option.name}
        onChange={(_, value) => {
          onChange(value)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              loading
                ? "Loading caption languages..."
                : "Import Caption Languages"
            }
          />
        )}
      />
    </>
  )
}
