import cleanYoutubeUrl from "./cleanYoutubeURL"

export function isValidYoutubeURL(youtubeURL: string): boolean {
  const cleaned = cleanYoutubeUrl(youtubeURL)
  return Boolean(cleaned)
}

export function isValidYoutubeURLMessage(youtubeURL: string): string {
  try {
    if (!youtubeURL.includes("youtu.be") && !youtubeURL.includes("youtube.com"))
      return "URL must contain youtu.be or youtube.com"

    // Check if the URL is in the format "youtube.com/live/<id>"
    if (
      youtubeURL.includes("www.youtube.com/live/") ||
      youtubeURL.includes("youtube.com/live/")
    ) {
      // Extract the video ID from the URL
      const videoIdMatch = youtubeURL.match(/\/live\/([\w-]+)/)
      if (videoIdMatch && videoIdMatch.length > 1) {
        return "" // Valid URL with video ID
      }
    }

    if (youtubeURL.includes("youtu.be")) {
      // Extract the video ID from the URL
      const videoIdMatch = youtubeURL.match(/youtu\.be\/([\w-]+)(\?.*)?$/)
      if (videoIdMatch && videoIdMatch.length > 1) {
        // Build and return the youtu.be URL with query parameters preserved
        return ""
      }
    }

    // Check for the youtu.be or regular watch?v= format
    const v = /^https?:\/\/(www\.)?youtu\.be/.test(youtubeURL)
      ? youtubeURL.replace(/^https?:\/\/(www\.)?youtu\.be\/([\w-]{11}).*/, "$2")
      : youtubeURL.replace(/.*\?v=([\w-]{11}).*/, "$1")

    if (v === youtubeURL || !v)
      return "Video ID could not be determined from URL"
    return ""
  } catch (err) {
    return "Invalid URL"
  }
}
